document.addEventListener("DOMContentLoaded", () => {
	// Get references to the modal, open button, and close button
	const modal = document.getElementById("myModal");
	const openModalBtn = document.getElementById("openModalBtn");
	const closeBtn = document.getElementsByClassName("close-modal")[0];
	const modalContent = document.querySelector(".modal-content");

	// Get the iframe element
	const videoFrame = document.getElementById("videoFrame");

	// Open the modal when the open button is clicked
	openModalBtn.addEventListener("click", () => {
			modal.style.display = "block";
			// Set the source of the iframe to load the video
			videoFrame.src = "https://www.youtube.com/embed/y1CbjbOwGmA?autoplay=1&rel=0&controls=0&modestbranding";
	});

	// Close the modal when the close button is clicked or when the overlay is clicked
	closeBtn.addEventListener("click", () => {
			modal.style.display = "none";
			// Pause the video when the modal is closed
			videoFrame.src = "";
	});

	// Close the modal when the modal overlay (outside the video) is clicked
	modal.addEventListener("click", (event) => {
		if (event.target === modal) {
				modal.style.display = "none";
				// Pause the video when the modal is closed
				videoFrame.src = "";
		}
	});

	// Prevent clicks within the modal content from closing the modal
	modalContent.addEventListener("click", (event) => {
		event.stopPropagation();
	});

});